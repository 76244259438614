html,
body {
    width: 100%;
    height: 100%;
}

.positiveAmount{
	color:#47a447 !important;
}

.negativeAmount{
	color:#d2322d !important;
}

.latestElement{
    border-top: thick double #6c757d;
}

.inactiveElement > td{
    background-color: rgba(255,255,0,0.1) !important;
}

.topBorder{
    border-top-style: groove;
    border-top-width: 2px;
}